/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import("./src/css/normalize.css");
/*
import "@fontsource/lato/100.css"
import "@fontsource/lato/100-italic.css"
import "@fontsource/lato/300.css"
import "@fontsource/lato/300-italic.css"
import "@fontsource/lato/400.css"
import "@fontsource/lato/400-italic.css"
*/
import("./src/css/main.css");
